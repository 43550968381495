import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Doodle #8",
  "featuredImage": "images/BCLC2.jpg",
  "coverAlt": "Doodle 8",
  "description": "Another doodle - a rebrand",
  "date": "2017-04-20",
  "dateModified": "2017-04-20",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Doodles", "Graphic Design"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`First things first, this is not abstract. There’s an acronym in there somewhere; if you know where I work right now, you know what the acronym is.`}</p>
    <p>{`Let’s call this a rebrand.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1400px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/afaae9aee1e672881a6288ebbcf25fe5/5814a/BCLC2.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBA//EABcBAAMBAAAAAAAAAAAAAAAAAAABBAL/2gAMAwEAAhADEAAAAb2Zmt6BbAWgP//EABoQAAEFAQAAAAAAAAAAAAAAAAEAAhARICH/2gAIAQEAAQUCki10Ox//xAAXEQEAAwAAAAAAAAAAAAAAAAABAhEg/9oACAEDAQE/AZKJWP/EABURAQEAAAAAAAAAAAAAAAAAACBB/9oACAECAQE/AYP/xAAXEAADAQAAAAAAAAAAAAAAAAABESAh/9oACAEBAAY/AoQGT//EABsQAAICAwEAAAAAAAAAAAAAAAERAFEQICGB/9oACAEBAAE/ISVO+W8KKBiwehn/2gAMAwEAAgADAAAAEIjv/v/EABcRAQEBAQAAAAAAAAAAAAAAAAEhABD/2gAIAQMBAT8QKBFvTf/EABYRAQEBAAAAAAAAAAAAAAAAAAEgUf/aAAgBAgEBPxABWx//xAAdEAEAAgICAwAAAAAAAAAAAAABESEAURBhIDFB/9oACAEBAAE/EAhPpqdYSVg1gt+kdcMQYJtwoAha0uuvAA0Z/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Doodle 8 - final version",
              "title": "Doodle 8 - final version",
              "src": "/static/afaae9aee1e672881a6288ebbcf25fe5/5814a/BCLC2.jpg",
              "srcSet": ["/static/afaae9aee1e672881a6288ebbcf25fe5/543cd/BCLC2.jpg 360w", "/static/afaae9aee1e672881a6288ebbcf25fe5/20801/BCLC2.jpg 720w", "/static/afaae9aee1e672881a6288ebbcf25fe5/5814a/BCLC2.jpg 1400w"],
              "sizes": "(max-width: 1400px) 100vw, 1400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Doodle 8 - final version`}</figcaption>{`
  `}</figure></p>
    <p><strong parentName="p">{`Alternate Version 1`}</strong></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1400px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e8ee9786569b455aa1d125fc9c4ca676/5814a/BCLC2bw.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMC/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABvlIuAAD/xAAZEAACAwEAAAAAAAAAAAAAAAAAARESICH/2gAIAQEAAQUCJ6MSd8f/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAWEAEBAQAAAAAAAAAAAAAAAAAgMYH/2gAIAQEABj8CE0//xAAcEAABAwUAAAAAAAAAAAAAAAABABARIDFBUaH/2gAIAQEAAT8hWLrBIhBaL0v/2gAMAwEAAgADAAAAEDPPPP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABwQAQABBAMAAAAAAAAAAAAAAAERACExURAgYf/aAAgBAQABPxBYzQJsgC0i7qOGIMTlpkCAeC66Nf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Doodle 8 - version 1",
              "title": "Doodle 8 - version 1",
              "src": "/static/e8ee9786569b455aa1d125fc9c4ca676/5814a/BCLC2bw.jpg",
              "srcSet": ["/static/e8ee9786569b455aa1d125fc9c4ca676/543cd/BCLC2bw.jpg 360w", "/static/e8ee9786569b455aa1d125fc9c4ca676/20801/BCLC2bw.jpg 720w", "/static/e8ee9786569b455aa1d125fc9c4ca676/5814a/BCLC2bw.jpg 1400w"],
              "sizes": "(max-width: 1400px) 100vw, 1400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Doodle 8 - version 1`}</figcaption>{`
  `}</figure></p>
    <p><strong parentName="p">{`Alternate Version 2`}</strong></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1400px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2ad87b20013b49c2cac2629430d2da29/5814a/BCLC2_not_used.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAXAQEAAwAAAAAAAAAAAAAAAAAAAQIE/9oADAMBAAIQAxAAAAHoqRkroIAAf//EABgQAAIDAAAAAAAAAAAAAAAAAAEQEiAh/9oACAEBAAEFAmRsa//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABkQAAIDAQAAAAAAAAAAAAAAAAERABAgMf/aAAgBAQAGPwK2zOnP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAECBB/9oACAEBAAE/IbaKS4IMr//aAAwDAQACAAMAAAAQFCC+/8QAFxEAAwEAAAAAAAAAAAAAAAAAAREhIP/aAAgBAwEBPxBCXH//xAAVEQEBAAAAAAAAAAAAAAAAAAAgQf/aAAgBAgEBPxCD/8QAGxABAQACAwEAAAAAAAAAAAAAAREAEDFBcdH/2gAIAQEAAT8Q2pETkJiHb6nzAhLduf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Doodle 8 - version 2",
              "title": "Doodle 8 - version 2",
              "src": "/static/2ad87b20013b49c2cac2629430d2da29/5814a/BCLC2_not_used.jpg",
              "srcSet": ["/static/2ad87b20013b49c2cac2629430d2da29/543cd/BCLC2_not_used.jpg 360w", "/static/2ad87b20013b49c2cac2629430d2da29/20801/BCLC2_not_used.jpg 720w", "/static/2ad87b20013b49c2cac2629430d2da29/5814a/BCLC2_not_used.jpg 1400w"],
              "sizes": "(max-width: 1400px) 100vw, 1400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Doodle 8 - version 2`}</figcaption>{`
  `}</figure></p>
    <p><strong parentName="p">{`Alternate Version 2`}</strong></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1400px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/867806604702b9afd0a141a893fa6238/5814a/BCLC2_not_used_2.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECA//EABcBAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAelxrpaNQCg//8QAFxAAAwEAAAAAAAAAAAAAAAAAEBEhIP/aAAgBAQABBQJ0K5//xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAwEBPwFj/8QAFREBAQAAAAAAAAAAAAAAAAAAICH/2gAIAQIBAT8Bo//EABYQAQEBAAAAAAAAAAAAAAAAABEQIP/aAAgBAQAGPwIrr//EABwQAAIBBQEAAAAAAAAAAAAAAAERABAgMUFRYf/aAAgBAQABPyFPSadC48QBBDFhn//aAAwDAQACAAMAAAAQKBCB/8QAGhEAAQUBAAAAAAAAAAAAAAAAEQABECExgf/aAAgBAwEBPxAKHU+z/8QAGREBAAIDAAAAAAAAAAAAAAAAAQARECFB/9oACAECAQE/EF5IXW8E/8QAGxABAQADAAMAAAAAAAAAAAAAAREAECExQVH/2gAIAQEAAT8QQCYih9w7BJZ50DnpMQkgsws7LsA8M//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Doodle 8 - version 3",
              "title": "Doodle 8 - version 3",
              "src": "/static/867806604702b9afd0a141a893fa6238/5814a/BCLC2_not_used_2.jpg",
              "srcSet": ["/static/867806604702b9afd0a141a893fa6238/543cd/BCLC2_not_used_2.jpg 360w", "/static/867806604702b9afd0a141a893fa6238/20801/BCLC2_not_used_2.jpg 720w", "/static/867806604702b9afd0a141a893fa6238/5814a/BCLC2_not_used_2.jpg 1400w"],
              "sizes": "(max-width: 1400px) 100vw, 1400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Doodle 8 - version 3`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      